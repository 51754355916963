import React from "react";
import imgS from "../../assets/image/media/home/svg/HomeDesign.svg";
import imgE from "../../assets/image/media/home/svg/HomeDevelopment.svg";
import imgR from "../../assets/image/media/home/svg/HomeSupport.svg";

const Progress = ({ className, id, ...rest }) => {
  return (
    <>
      <div className={className} id={id} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-9 col-xs-10">
              <div className="text-center pt-13 pt-lg-23 pb-13 pb-lg-12 pr-lg-10 pr-xl-0">
                <h2 className="font-size-10 mb-0">
                  Full-stack web engineering
                </h2>
                <p className="mt-10">
                  A robust, result-oriented development process that brings your
                  concept to life without cutting corners.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Section Title --> */}
          {/* <!-- Progress Items --> */}
          <div className="row justify-content-center">
            <div className="col-sm-12 col-xs-8">
              <div className="timeline-area d-sm-flex just justify-content-lg-between flex-wrap flex-lg-nowrap position-relative">
                {/* <!-- Image Group --> */}

                {/* <!-- Single Progress --> */}

                <div
                  className="single-widgets pr-md-18 pr-lg-10 mb-10 mb-lg-0 text-center text-md-left "
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  <div className="fbox-img bg-whitesmoke-gradient dodger-blue-3 rounded-10 mb-10  mx-auto mx-md-0">
                    <img className="w-100" src={imgS} alt="" />
                  </div>
                  <div className="text-center">
                    <h3 className="font-size-8 mb-6">Design</h3>
                    <p className="font-size-5 line-height-28 mb-0">
                      Every project starts with a visual. We balance innovative
                      thinking with brand compliance, and always keep the user
                      experience at the center of the process. Our creative team
                      interprets your concept — following an iterative process
                      to nail down exactly what you have envisioned. And we
                      consult with you at each stage as we finesse and polish
                      each element of the final design.
                    </p>
                  </div>
                </div>

                {/* <!-- End Single Progress --> */}
                {/* <!-- Single Progress --> */}

                <div
                  className="single-widgets pr-md-18 pr-lg-10 mb-10 mb-lg-0 text-center text-md-left"
                  data-aos="zoom-in"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  <div className="fbox-img bg-whitesmoke-gradient dodger-blue-3 rounded-10 mb-10  mx-auto mx-md-0">
                    <img className="w-100" src={imgE} alt="singleImg" />
                  </div>
                  <div className="text-center">
                    <h3 className="font-size-8 mb-6">Develop</h3>
                    <p className="font-size-5 line-height-28 mb-0">
                      As we move to the build phase, we’ll advise you on the
                      right technologies and infrastructure to underpin your
                      application—ensuring it’s secure, stable, and scalable.
                      Our Agile development methodology allows us to prototype
                      quickly, and test, refine and improve at each stage of
                      development — plotting the most efficient route to a
                      market-ready product.
                    </p>
                  </div>
                </div>

                {/* <!-- End Single Progress --> */}
                {/* <!-- Single Progress --> */}

                <div
                  className="single-widgets pr-md-18 pr-lg-10 mb-10 mb-lg-0 text-center text-md-left "
                  data-aos="zoom-in"
                  data-aos-delay="900"
                  data-aos-once="true"
                >
                  <div className="fbox-img bg-whitesmoke-gradient dodger-blue-3 rounded-10 mb-10  mx-auto mx-md-0">
                    <img className="w-100" src={imgR} alt="" />
                  </div>
                  <div className="text-center">
                    <h3 className="font-size-8 mb-6">Support</h3>
                    <p className="font-size-5 line-height-28 mb-0">
                      Post-launch, we’re on hand to support you as you promote
                      and scale your product. We perform detailed analysis on
                      engagement, uptake, and usage, allowing us to pinpoint
                      areas for further improvement. And behind the scenes, we
                      proactively monitor and manage the application and
                      environment — allowing you to focus on building the user
                      base.
                    </p>
                  </div>
                </div>

                {/* <!-- End Single Progress --> */}
              </div>
            </div>
          </div>
          {/* <!-- End Progress Items --> */}
        </div>
      </div>
    </>
  );
};

export default Progress;
