import React from "react";

const SuppSer = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-12 pb-13 pb-lg-18">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-8  col-lg-8 col-md-8 col-xs-10"
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <div className="pl-lg-10 pt-11 pt-lg-0 text-center">
              <p className="font-size-5 mb-8 heading-text">
                SUPPORTING SERVICES
              </p>
              <h2 className="font-size-10 mb-8">
                We go way beyond what you’d expect from a traditional
                development agency.
              </h2>
              <p className="font-size-6 mb-0">
                Our suite of supporting services is designed to give your
                product the most effective and impactful launch.{""}
              </p>
            </div>
          </div>

          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default SuppSer;
