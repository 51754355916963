import React from "react";
import imageOne from "../../assets/image/media/home/png/HomeCreative.png";
const ImgCon2 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-12 pb-13 pb-lg-18">
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-xs-8 order-2 order-lg-1">
            <div
              className="mt-8 mt-lg-0"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <h5 className="font-size-8 mb-8">Creative yet practical</h5>
              <p className="font-size-6 mb-0">
                We’re innovators, and we’re dreamers, but we don’t have our
                heads in the clouds. Our creative approach is always tightly
                focused on delivering a pragmatic and efficient solution with
                real-world application.
              </p>
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-xl-6 offset-xl-2 col-lg-7 col-md-6 col-xs-8 order-1 order-md-2">
            <div className="pt-lg-3" data-aos="flip-right" data-aos-delay={900}>
              <div className="l3-content-image-5 mt-5 mt-lg-0">
                <img className="w-100" src={imageOne} alt="heroImg" />
              </div>
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default ImgCon2;
