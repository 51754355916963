import React from "react";
import imgBg from "../../assets/image/media/home/jpg/bg-01.jpg";

const Stat = ({ className, ...rest }) => {
  return (
    <div
      className={className}
      {...rest}
      css={`
        background-image: url(${imgBg});
      `}
    >
      <div className="container pt-15 pt-lg-22 pb-13 pb-lg-18">
        <div className="row align-items-center">
          <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 border-right text-color-white">
            <h2 className="text-color-white">
              <span>60</span>%
            </h2>
            <h5 className="font-size-8 mb-8 text-color-white">
              Faster development
            </h5>
            <p className="font-size-6 mb-5 text-color-white">
              Using parallel development helps us speed up the delivery process
              without sacrificing quality.
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 border-right">
            <h2 className="text-color-white">
              <span>70</span>%
            </h2>
            <h5 className="font-size-8 mb-8 text-color-white">Less rework</h5>
            <p className="font-size-6 mb-5 text-color-white">
              Using an Agile methodology and prototyping frequently means less
              time wasted fixing problems down the line.
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4">
            <h2 className="text-color-white">
              <span>100</span>%
            </h2>
            <h5 className="font-size-8 mb-8 text-color-white">
              Customer satisfaction
            </h5>
            <p className="font-size-6 mb-5 text-color-white">
              Our clients are overwhelmingly delighted with the results of our
              collaborative development process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stat;
