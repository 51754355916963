import { Link } from "gatsby";
import React from "react";
import PageWrapper from "../components/PageWrapper";
import Feature from "../sections/homepage/Feature";
import Hero from "../sections/homepage/Hero";
import Progress from "../sections/homepage/Progress";
import Brands from "../sections/homepage/brands";
import CtaSec from "../sections/homepage/cta";
import ImgCon from "../sections/homepage/image-content";
import ImgCon2 from "../sections/homepage/image-content-2";
import Stat from "../sections/homepage/statistics";
import SuppSer from "../sections/homepage/supporting-services";

const IndexPage = () => {

  return (
    <>
      
      <PageWrapper themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              
              <Link to="/contact">
              <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                Let's talk
              </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
        >
        <Hero className="pt-15 pt-lg-26 pb-8 pb-lg-18 bg-gradient-1"   />
        <Progress className="pb-16" id="services"/>
        <ImgCon />
        <SuppSer />
        <Feature />
        <Stat />
        <ImgCon2 />
        <Brands />
        <CtaSec />
        
      </PageWrapper>
      
      
    </>
  );
};
export default IndexPage;
