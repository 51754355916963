import React from "react";
import icon1 from "../../assets/image/media/home/svg/HomeMarketResearch.svg";
import icon2 from "../../assets/image/media/home/svg/HomeUserTesting.svg";
import icon3 from "../../assets/image/media/home/svg/HomeWebDev.svg";
import icon4 from "../../assets/image/media/home/svg/HomeDigitalMarket.svg";
import icon5 from "../../assets/image/media/home/svg/HomeBrand.svg";
import icon6 from "../../assets/image/media/home/svg/HomeSEO.svg";
import FeatureCard2 from "../../components/FreatureCard2/FreatureCard2";
const items = [
  {
    icon: icon1,
    title: "Market Research",
    content:
      "We’ll help you evaluate your target market and audience, identifying the key features that will make a winning product.",
  },
  {
    icon: icon2,
    title: "User testing",
    content:
      "Our team can assist in arranging user group testing to road-test wireframes and prototypes before starting development.",
  },
  {
    icon: icon3,
    title: "Web Development",
    content:
      "An engaging and appealing web presence is key to your product launch — and we have the creative expertise to deliver it.",
  },
  {
    icon: icon4,
    title: "Digital Marketing",
    content:
      "We’ll support you to execute a successful teaser or launch campaign, across email, social, paid ads, and other channels.",
  },
  {
    icon: icon5,
    title: "Brand Design",
    content:
      "Whether you’re building a brand or refreshing your current one, our design team is on hand to create your new identity.",
  },
  {
    icon: icon6,
    title: "SEO & SEM",
    content:
      "We can help you to optimize your product website or landing page to ensure you appear high in the search rankings.",
  },
];

const Feature = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-7 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83">Our values</h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-6 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FeatureCard2 options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
