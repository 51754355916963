import React from "react";
import { Link } from "gatsby";
import imgD from "../../assets/image/media/home/png/HomeHero.png";
//import imgC1 from "../../assets/image/home-2/png/card-img-1.png";
//import imgDBG from "../../assets/image/home-1/png/dot-bg.png";
//import imgCS from "../../assets/image/home-2/png/content-shape.png";

const Hero = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* <!-- Hero Content --> */}
            <div
              className="col-xl-5 col-lg-7 col-md-10 col-sm-10 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="pr-lg-10 pr-xl-0 pt-0 pt-lg-0 pb-13 pb-lg-0">
                <h1 className="font-size-10 mb-8">
                  Your ideas realized faster than you thought possible
                </h1>
                <p className="font-size-6 mb-0">
                  Powerful, scalable, and creative web applications.
                </p>
                <div className=" pt-5 pt-lg-10">
                  <Link
                    to={"/contact"}
                    className="btn btn-fox-violet rounded-5 pl-10 pr-10"
                  >
                    Let's talk about your new project!
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- End Hero Content --> */}
            {/* <!-- l2-hero-image-group --> */}
            {/* Image Section */}

            <div className="col-xl-7 col-lg-6 col-xs-10 position-static order-1 order-md-2">
              <div
                className="l2-content-image-group-1 w-100"
                data-aos="zoom-in"
                data-aos-delay={300}
                data-aos-once="true"
              >
                {/* Dashboard Img */}
                <img className="w-100 " src={imgD} alt="dashImg" />

                {/* End Dashboard Img */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
