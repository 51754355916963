import React from "react";
import imageOne from "../../assets/image/media/home/png/HomeCustomer.png";
import imageTwo from "../../assets/image/media/home/png/HomeAdvanced.png";

const ImgCon = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-12 pb-13 pb-lg-18">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-xs-9"
            data-aos="fade-right"
            data-aos-delay={500}
          >
            <img className="w-100" src={imageOne} alt="heroImg" />
          </div>
          <div
            className="col-xl-5 offset-xl-1 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <div className="pl-lg-10 pt-11 pt-lg-0">
              <p className="font-size-5 mb-5 heading-text">
                IT'S ALL ABOUT YOU
              </p>
              <h2 className="font-size-10 mb-8">A customer-centric approach</h2>
              <p className="font-size-6 mb-0">
                Successful products are born out of synergy—between the
                visionary who comes up with the idea, and the team that
                implements it. That’s why you—the client — will always be at the
                heart of our development process.
                {""}
              </p>

              <p className="font-size-6 mb-0">
                From initial discovery, through design, development, and
                testing, we’ll ensure you’re informed, involved, and most
                importantly heard.
              </p>
              {/* <div className="d-flex align-items-center py-6 mr-xl-15">
              <Link to={"#"} className="btn btn-blue-3 btn-3 rounded-5">
                Get started
                  </Link>
                
              </div> */}
            </div>
          </div>

          <div className="container pt-25 pt-lg-31 pb-13 pb-lg-18">
            <div className="row align-items-center">
              {/* Hero Content */}
              <div className="col-xl-4 col-lg-5 col-md-6 col-xs-8 order-2 order-lg-1">
                <div
                  className="mt-8 mt-lg-0"
                  data-aos="fade-right"
                  data-aos-delay={500}
                >
                  <h2 className="font-size-10 mb-8">
                    Advanced performance made easy
                  </h2>
                  <p className="font-size-6 mb-0">
                    We don’t just create products that work—we create products
                    that outperform the competition, today and into the future.
                    We do this by combining innovative design, cutting-edge
                    technologies, the latest UI/UX best practice, and the most
                    reliable infrastructure to ensure every product we ship is
                    perfectly positioned not to enter the market — but to lead
                    it.
                  </p>
                </div>
              </div>
              {/* End Hero Content */}
              {/* Hero Image */}
              <div className="col-xl-6 offset-xl-2 col-lg-7 col-md-6 col-xs-8 order-1 order-md-2">
                <div
                  className="pt-lg-3"
                  data-aos="flip-right"
                  data-aos-delay={900}
                >
                  <div className="l3-content-image-5 mt-5 mt-lg-0">
                    <img className="w-100" src={imageTwo} alt="heroImg" />
                  </div>
                </div>
              </div>
              {/* End Hero Image */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgCon;
